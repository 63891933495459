@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-family: TitilliumWeb-Regular;@primary-color: #1537fa;@primary-color-active: #061fb0;@disabled-color: #f0f0f0;@disabled-bg: rgba(21, 55, 250, 0.2);@btn-font-weight: bold;@link-color: #595959;@link-hover-color: #000;@link-active-color: #8D8C8C;@link-decoration: underline;@link-hover-decoration: underline;@error-color: #f2103f;@success-color: #56BA7E;@alert-success-border-color: #56BA7E;@warning-color: #F4B162;@alert-warning-border-color: #F4B162;